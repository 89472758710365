<div class='comman-modal ' *ngIf="!(serverName)">
    <div class="modal-header border-0 py-0">
        <h4 class="modal-title pull-left border-0"><b>Add New Product</b></h4>
    </div>
    <form action="" [formGroup]="productForm">
        <div class="modal-body pt-0 pb-0">
            <div class="row mb-3">
                <label for="fullName" class="col-sm-3 col-form-label">Full Name</label>
                <div class="col-sm-9 mt-2">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Name"
                        formControlName="name">
                    <div *ngIf="productForm.get('name')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="productForm.get('name')?.errors?.['required'] && (productForm.get('name')?.dirty || productForm.get('name')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                            </p>
                        </div>
                        <div class="error-message" *ngIf="productForm.get('name')?.errors?.['pattern']">
                            <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <label for="fullName" class="col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9 mt-2">
                    <textarea class="description text-area" id="fullName" placeholder="Description"
                        style="overflow:hidden" formControlName="description"></textarea>
                </div>
            </div>
        </div>

        <div class="modal-footer border-0 p-0">
            <div class="row mb-3 pe-2">
                <div class="col-sm-12 mt-2 text-end">
                    <button type="submit" class="comman-btn1 btn-primary" (click)="submit()">Submit</button>
                    <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                        (click)="closedModal()">Cancel</button>
                </div>
            </div>
        </div>
    </form>
</div>



<!-- --------------------------------- -->
<div class="comman-modal" *ngIf="serverName">

    <div class="modal-header border-0 py-0">
        <h4 class="modal-title pull-left border-0"><b>Change User Instance</b></h4>
    </div>
    <div class="modal-body pt-0" *ngIf="!isShow">
        <form action="" [formGroup]="productForm">
            <div class="row">

                <div class="col-sm-12">
                    <label for="fullName" class="mb-2"><b>Select source Instance</b></label>
                    <div class="dropdown-field custom-select custom-select1">
                        <div class="dropdown psd">
                            <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {{sourceInstance ? sourceInstance : 'Select instance'}}
                            </p>
                            <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-4 pe-4"
                                aria-labelledby="dropdownMenuButton">
                                <div class="row search ps-0 pe-0 pt-2 pb-3 align-items-center">
                                    <div class="col-12">
                                        <input type="text" class="form-control" formControlName="sourceSearchText"
                                            placeholder="Search here" />
                                    </div>
                                </div>
                                <div class="customerList"
                                    *ngFor="let instance of sourceInstanceList  | filter: sourceSearchText:'serverName'">
                                    <li (click)="selectInstance(instance,'source')">
                                        {{instance.serverName+'.'+instance.drive}}
                                    </li>
                                </div>
                            </ul>
                            <div
                                *ngIf="productForm.get('source')?.errors?.['required'] && (productForm.get('source')?.dirty || productForm.get('source')?.touched)">
                                <p class="text-danger m-0 p-0">
                                    <small>It is mandatory field.</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">

                <div class="col-sm-12">
                    <label for="fullName" class="mb-2"><b>Select Destination Instance</b></label>
                    <div class="dropdown-field custom-select custom-select1">
                        <div class="dropdown psd">
                            <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {{destinationInstance ? destinationInstance : 'Select instance'}}
                            </p>
                            <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-4 pe-4"
                                aria-labelledby="dropdownMenuButton">
                                <div class="row search ps-0 pe-0 pt-2 pb-3 align-items-center">
                                    <div class="col-12">
                                        <input type="text" class="form-control" formControlName="searchText"
                                            placeholder="Search here" />
                                    </div>
                                </div>
                                <div class="customerList"
                                    *ngFor="let instance of destinationInstanceList  | filter: searchText:'serverName'">
                                    <li (click)="selectInstance(instance,'destination')">
                                        {{instance.serverName+'.'+instance.drive}}
                                    </li>
                                </div>
                            </ul>
                            <div
                                *ngIf="productForm.get('destination')?.errors?.['required'] && (productForm.get('destination')?.dirty || productForm.get('destination')?.touched)">
                                <p class="text-danger m-0 p-0">
                                    <small>It is mandatory field.</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-body pt-0 pb-2" *ngIf="isShow">
        <div class="row">
            <p class="text">Are you sure you want to move <b>{{sourceInstance}}</b> to <b>{{destinationInstance}}</b>?
            </p>
        </div>
    </div>

    <div class="text-end border-0">
        <button class="comman-btn1 btn-primary me-2" (click)='handleSubmit()'>submit</button>
        <button class="comman-btn1 btn-secondary text-light me-4" (click)="cancel()">Cancel</button>
    </div>
</div>