import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CreateProductRoot } from 'src/app/interfaces/product';
import { InstanceService } from 'src/app/services/instance.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() serverName!: any
  @Input() drive!: any
  isShow: boolean = false
  sourceInstanceList: any = []
  destinationInstanceList: any = []
  productForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    description: new FormControl(''),
    sourceName: new FormControl('', [Validators.required]),
    destinationName: new FormControl('', [Validators.required]),
    searchText: new FormControl(''),
    destinationId: new FormControl(''),
    sourceId: new FormControl(''),
    sourceSearchText: new FormControl('')
  })

  constructor(
    private productService: ProductService,
    private router: Router,
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
    private instanceService: InstanceService
  ) { }

  ngOnInit(): void {
    this.instanceForMigration()
    this.getAllfreeInstance()
  }

  submit() {
    if (!this.productForm.valid) {
      this.productForm.markAllAsTouched();
    }
    else {
      this.addProduct();
    }
  }

  addProduct() {
    const productPayload = this.productForm.value
    this.productService.createProduct(productPayload).subscribe((res: CreateProductRoot) => {
      if (res.success) {
        this.closedModal();
        this.router.navigate(['product/add-product'], { queryParams: { productId: res.product._id } })
        this.event.emit('ok');

        this.productForm.reset();
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      }
    )
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  getAllfreeInstance() {
    this.instanceService.getAllfreeInstance().subscribe((res: any) => {
      if (res.success) {
        this.destinationInstanceList = res.allFreeInstances
      }
    }, (err) => {
      this.toasts.error(err.error.message)
    })
  }

  instanceForMigration() {
    this.instanceService.instanceForMigration().subscribe((res: any) => {
      if (res.success) {
        this.sourceInstanceList = res?.instanceReadyForMigration
      }
    }, (err) => {
      this.toasts.error(err.error.message)
    })
  }

  handleSubmit() {
    if (!this.productForm.controls.destinationName.valid) {
      this.productForm.markAllAsTouched()
    } else {
      if (!this.isShow) {
        this.isShow = true;
      } else {
        this.changeDestination();
      }
    }

  }

  changeDestination() {
    const payload = {
      sourceInstanceId: this.productForm.controls.sourceId.value,
      destinationInstanceId: this.productForm.controls.destinationId.value,
    }
    this.instanceService.changeInstance(payload).subscribe((res: any) => {
      if (res.success) {
        this.bsModalRef.hide();
        this.toasts.success(res.message)
        this.event.emit('ok')
      }
    }, (err) => {
      this.bsModalRef.hide();
      this.toasts.error(err.error.message)
    })
  }
  cancel() {
    if (!this.isShow) {
      this.bsModalRef.hide()
    }
    else {
      this.isShow = false
    }
  }

  selectInstance(instance: any, type: string) {
    const instanceName = instance.serverName + '.' + instance.drive
    const instanceId = instance._id
    if (type == 'source') {
      this.productForm.controls.sourceName.setValue(instanceName)
      this.productForm.controls.sourceId.setValue(instanceId)
    } else {
      this.productForm.controls.destinationName.setValue(instanceName)
      this.productForm.controls.destinationId.setValue(instanceId)
    }
  }

  get searchText() {
    return this.productForm.controls.searchText.value || '';

  }

  get sourceSearchText() {
    return this.productForm.controls.sourceSearchText.value || '';
  }
  get destinationInstance() {
    return this.productForm.controls.destinationName.value || '';
  }

  get sourceInstance() {
    return this.productForm.controls.sourceName.value || '';
  }
}
